<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img
        src="@/assets/images/side-bar-logo.png"
        alt=""
        class="mx-3"
        width="200"
        length="200"
        v-show="!minimize"
      />
    </CSidebarBrand>

    <!-- <CRenderFunction flat :content-to-render="$options.nav" /> -->

    <ul class="c-sidebar-nav h-100 ps" style="position: relative">
      <li v-if="canAccess('Dashboard', role)" class="c-sidebar-nav-item">
        <router-link to="/dashboard" class="c-sidebar-nav-link" target="_self">
          <i class="bi bi-speedometer c-sidebar-nav-icon"></i>
          Dashboard</router-link
        >
      </li>
      <li v-if="canAccess('Calendar', role)" class="c-sidebar-nav-item">
        <router-link to="/calendar" class="c-sidebar-nav-link" target="_self">
          <i class="bi bi-calendar-week c-sidebar-nav-icon"></i> Calendar of
          Activities</router-link
        >
      </li>

      <li
        v-if="canAccess('accomplishment_report', role)"
        class="c-sidebar-nav-item"
      >
        <router-link
          to="/accomplishment-report"
          class="c-sidebar-nav-link"
          target="_self"
        >
          <i
            class="bi bi-file-earmark-text-fill c-sidebar-nav-icon"
            style=""
          ></i>
          Accomplishment Report</router-link
        >
      </li>

      <li v-if="canAccess('weekly_plan', role)" class="c-sidebar-nav-item">
        <router-link
          to="/weekly-plan"
          class="c-sidebar-nav-link"
          target="_self"
        >
          <i class="bi bi-calendar-range-fill c-sidebar-nav-icon" style=""></i>
          Weekly Plan</router-link
        >
      </li>

      <li v-if="canAccess('HEIs', role)" class="c-sidebar-nav-item">
        <router-link to="/heis" class="c-sidebar-nav-link" target="_self">
          <i class="bi bi-building c-sidebar-nav-icon" style=""></i>
          HEIs</router-link
        >
      </li>
      <li
        v-if="canAccess('RecognizedPrograms', role)"
        class="c-sidebar-nav-item"
      >
        <router-link
          to="/recognized-programs"
          class="c-sidebar-nav-link"
          target="_self"
        >
          <i class="bi bi-card-checklist c-sidebar-nav-icon"></i>
          PhQuaR
        </router-link>
      </li>
      <li v-if="canAccess('Programs', role)" class="c-sidebar-nav-item">
        <router-link to="/programs" class="c-sidebar-nav-link" target="_self">
          <i class="bi bi-card-checklist c-sidebar-nav-icon"></i>
          Program List</router-link
        >
      </li>
      <li
        v-if="canAccess('ProgramApplications', role)"
        class="c-sidebar-nav-item"
      >
        <router-link
          to="/program-apps"
          class="c-sidebar-nav-link"
          target="_self"
        >
          <i class="bi bi-cloud-upload c-sidebar-nav-icon"></i>
          Program Applications</router-link
        >
      </li>

      <li
        v-if="canAccess('RecordsDashboard', role)"
        class="c-sidebar-nav-dropdown"
        @click="showDropdown"
      >
        <a class="c-sidebar-nav-dropdown-toggle">
          <i class="bi bi-archive c-sidebar-nav-icon"></i>Records (EVCS)</a
        >
        <ul class="c-sidebar-nav-dropdown-items">
          <li class="c-sidebar-nav-item">
            <router-link
              to="/records/dashboard"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i class="bi bi-speedometer c-sidebar-nav-icon"></i>
              Dashboard</router-link
            >
          </li>
          <li class="c-sidebar-nav-item disabled">
            <router-link
              to="/records/students"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i class="bi bi-person-check c-sidebar-nav-icon"></i>
              Student Records</router-link
            >
          </li>

          <li class="c-sidebar-nav-item">
            <router-link
              to="/records/special-orders-old"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i
                class="bi bi-file-earmark-check c-sidebar-nav-icon"
                style=""
              ></i>
              Special Orders (OLD)</router-link
            >
          </li>

          <li class="c-sidebar-nav-item">
            <router-link
              to="/records/cav-applications"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i class="bi bi-folder-check c-sidebar-nav-icon" style=""></i>
              CAV Applications</router-link
            >
          </li>

          <li class="c-sidebar-nav-item">
            <router-link
              to="/records/cavs"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i class="bi bi-list-ul c-sidebar-nav-icon" style=""></i>
              CAV Issuances</router-link
            >
          </li>

          <li class="c-sidebar-nav-item">
            <router-link
              to="/records/payments"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i class="c-sidebar-nav-icon" style="">&#8369;</i>
              Payments</router-link
            >
          </li>
        </ul>
      </li>

      <li
        v-if="canAccess('HR', role)"
        class="c-sidebar-nav-dropdown"
        @click="showDropdown"
      >
        <a class="c-sidebar-nav-dropdown-toggle">
          <i class="bi bi-people c-sidebar-nav-icon" style=""></i> Employee
          Management</a
        >

        <ul class="c-sidebar-nav-dropdown-items">
          <li class="c-sidebar-nav-item" v-if="canAccess('HR', role)">
            <router-link to="/hr/dtr" class="c-sidebar-nav-link" target="_self">
              <i class="bi bi-stopwatch c-sidebar-nav-icon" style=""></i>
              Daily Time Records</router-link
            >
          </li>
        </ul>
      </li>

      <!-- 
      # Deprecated by Calwin 12/17/2024
      # No longer used

      <li
        v-if="canAccess('planting_trees', role)"
        class="c-sidebar-nav-dropdown"
        @click="showDropdown"
      >
        <a class="c-sidebar-nav-dropdown-toggle">
          <i class="bi bi-cpu c-sidebar-nav-icon" style=""></i>Planting Trees
        </a>
        <ul class="c-sidebar-nav-dropdown-items">
          <li class="c-sidebar-nav-item">
            <router-link
              to="/planting-trees/add-school"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i class="bi bi-basket c-sidebar-nav-icon" style=""></i>
              List of HEIs</router-link
            >
          </li>
          <li class="c-sidebar-nav-item">
            <router-link
              to="/planting-trees/plant"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i class="bi bi-tree-fill c-sidebar-nav-icon" style=""></i>
              Plant Trees</router-link
            >
          </li>
        </ul>
      </li> -->
      <li v-if="canAccess('CAVApplication', role)" class="c-sidebar-nav-item">
        <router-link
          to="/cav-application"
          class="c-sidebar-nav-link"
          target="_self"
        >
          <i class="bi bi-cloud-upload c-sidebar-nav-icon"></i>
          CAV Application</router-link
        >
      </li>

      <li v-if="canAccess('Nstp', role)" class="c-sidebar-nav-item">
        <router-link to="/admin/nstp" class="c-sidebar-nav-link" target="_self">
          <i class="bi bi-cloud-upload c-sidebar-nav-icon"></i>
          NSTP Portal
          <span
            v-show="$store.state.hasSerialNumberRequests"
            class="badge badge-danger"
            >NEW</span
          ></router-link
        >
      </li>

      <li v-if="canAccess('Tosf', role)" class="c-sidebar-nav-item">
        <router-link to="/tosf" class="c-sidebar-nav-link" target="_self">
          <i class="bi bi-card-checklist c-sidebar-nav-icon"></i>
          TOSF</router-link
        >
      </li>

      <li v-if="canAccess('DTS', role)" class="c-sidebar-nav-item">
        <router-link to="/dts" class="c-sidebar-nav-link" target="_self">
          <i class="bi bi-signpost-split c-sidebar-nav-icon"></i>
          Document Tracking
          <span
            v-show="$store.state.hasIncomingDocuments"
            class="badge badge-danger"
            >NEW</span
          ></router-link
        >
      </li>
      <li
        v-if="canAccess('SmsNotificationCenter', role)"
        class="c-sidebar-nav-item"
      >
        <router-link
          to="/sms-notification-center"
          class="c-sidebar-nav-link"
          target="_self"
        >
          <i class="bi bi-chat-text c-sidebar-nav-icon"></i>
          SMS Alert Service</router-link
        >
      </li>

      <li
        v-if="canAccess('Support', role)"
        class="c-sidebar-nav-dropdown"
        @click="showDropdown"
      >
        <a class="c-sidebar-nav-dropdown-toggle">
          <i class="bi bi-headset c-sidebar-nav-icon"></i> Report an issue</a
        >

        <ul class="c-sidebar-nav-dropdown-items">
          <li class="c-sidebar-nav-item" v-if="canAccess('Support', role)">
            <router-link
              to="/support/tickets"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i class="bi bi-ticket-perforated c-sidebar-nav-icon"></i>
              Create a ticket</router-link
            >
          </li>
          <li class="c-sidebar-nav-item" v-if="canAccess('Support', role)">
            <router-link
              to="/support/job-order-referrals"
              class="c-sidebar-nav-link"
              target="_self"
            >
              <i
                class="bi bi-file-earmark-arrow-down c-sidebar-nav-icon"
                style=""
              ></i>
              Job Order Referrals</router-link
            >
          </li>
        </ul>
      </li>
    </ul>

    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import { canAccess } from "../middlewares/auth";

export default {
  name: "TheSidebar",
  nav,
  data() {
    return {
      items: [],
      role: this.$store.state.user.role,
    };
  },
  methods: {
    canAccess,
    showDropdown(event) {
      let el = event.target.parentElement;
      el.classList.contains("c-show")
        ? el.classList.remove("c-show")
        : el.classList.add("c-show");
    },
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },

  mounted() {},
};
</script>
